<template>
  <div class="pb-1">
    <NavBarSearch :payload="payloadSearch" />

    <ResultHeaderTab />

    <!-- ANCHOR - KẾT QUẢ TÌM KIẾM TÀU -->
    <b-tabs
      v-model="tabIndexRef"
      nav-wrapper-class="d-none"
    >
      <b-tab
        v-for="(_, itineraryIndex) in getSearchPayloadArray"
        :key="itineraryIndex"
      >
        <template v-if="loading">
          <div class="d-flex-center text-primary mt-2 py-2">
            <b-spinner
              variant="primary"
              class="mr-50"
            />
            <span>{{ $t('train.search.result.searching') }}</span>
          </div>
        </template>
        <template v-else-if="isEmpty(getResultTrainArray[itineraryIndex]) && !loading">
          <b-alert
            show
            variant="warning"
            class="my-2 my-lg-3"
          >
            <div class="text-center alert-heading font-medium-4">
              {{ $t('train.search.result.noTrainFound') }}
            </div>
          </b-alert>
        </template>
        <template v-else-if="!isEmpty(getResultTrainArray[itineraryIndex])">
          <div>
            <TrainFront
              :itineraryIndex="itineraryIndex"
              :trains="getResultTrainArray[itineraryIndex]"
            />
          </div>
          <div>
            <div class="fw-700 font-medium-3 text-center mb-50 text-uppercase">
              BẢNG GIÁ VÉ - <span
                v-if="trainName"
                class="text-danger"
              > Tàu {{ trainName }}</span>
            </div>

            <b-table
              class="mb-50 border rounded-8px"
              bordered
              responsive
              small
              hover
              :items="dataFare"
              :busy="isBusy"
              :show-empty="!isBusy && !loadingGetFares && isEmpty(dataFare)"
              :empty-text="$t('noMatchingResult')"
              :fields="['STT', 'seatName', 'seatCode', 'ticketPrice']"
              thead-class="text-dark font-weight-bolder"
            >
              <template
                v-for="column in ['STT', 'seatName', 'seatCode', 'ticketPrice']"
                #[`head(${column})`]="data"
              >
                <span :key="column.key">
                  {{ $t(`train.search.result.columns.${data.column}`) }}
                </span>
              </template>

              <template #table-busy>
                <div class="text-center text-warning my-2">
                  <b-spinner class="align-middle" />
                  <strong class="ml-1">{{ $t('train.search.result.searching') }}</strong>
                </div>
              </template>

              <template #cell(STT)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(seatName)="{item}">
                {{ item.seatTypeName }}
              </template>
              <template #cell(seatCode)="{item}">
                <span class="fw-700">{{ item.seatTypeCode }}</span>
              </template>
              <template #cell(ticketPrice)="{item}">
                <span
                  v-if="item.totalPrice"
                  :class="`fw-700 text-info
                    ${isMobileView ? 'font-medium-1' : 'font-medium-2'}
                  `"
                >
                  {{ formatCurrency(item.totalPrice) }}
                </span>
                <span
                  v-else
                  class="fw-700 font-medium-1 text-danger"
                >
                  {{ $t('train.search.result.notHavePrice') }}
                </span>
              </template>
            </b-table>
          </div>
        </template>
      </b-tab>
    </b-tabs>

    <!-- ANCHOR - BUTTON: BACK, CONFIRM -->
    <div class="d-flex-center mt-2">
      <b-button
        variant="outline-secondary"
        pill
        @click="handleBackToSearch"
      >
        {{ $t('train.search.result.goBack') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BTabs, BTab, BAlert,
  BSpinner, BTable,
} from 'bootstrap-vue'
import {
  ref,
  computed,
  onBeforeMount,
  onUnmounted,
  watch,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import {
  convertISODateTime,
  formatCurrency,
} from '@core/utils/filter'

import useTrainHandle from '@train/useTrainHandle'

import useToast from '@useToast'

export default {
  components: {
    BButton,
    BTabs,
    BTab,
    BAlert,
    BSpinner,
    BTable,

    NavBarSearch: () => import('../train-search/NavBarSearch.vue'),
    ResultHeaderTab: () => import('../train-result-search/components/ResultHeaderTab.vue'),
    TrainFront: () => import('./TrainFront.vue'),
  },
  setup() {
    const { router } = useRouter()
    const { toastWarning, toastError } = useToast()
    const {
      loading,
      loadingGetFares,
      tabIndex,
      stateTrainSelectedArray,

      getBookingCode,
      getSearchPayloadArray,
      getResultTrainArray,

      searchTrain,
    } = useTrainHandle()

    const payloadSearch = JSON.parse(localStorage.getItem('trainSearch'))
    const trainName = ref('')
    const isBusy = ref(false)
    const dataFare = ref(null)

    async function getFaresByTrain(trainId, name) {
      const payload = {
        bookingCode: getBookingCode.value,
        trainId,
      }
      dataFare.value = []
      isBusy.value = true
      trainName.value = ''

      try {
        const data = await store.dispatch('app-train/searchFaresByTrain', payload)
        // dataFare.value = data?.fareList || []
        dataFare.value = !isEmpty(data?.fareList) && data.fareList.every(it => isNumber(it.totalPrice)) ? data.fareList.sort((a, b) => b.totalPrice - a.totalPrice) : []
        trainName.value = name
      } catch (error) {
        toastError({ title: 'train.search.result.searchError' })
        console.error('error getTrainFare', error)
      } finally {
        isBusy.value = false
      }
    }

    watch([() => tabIndex.value, () => stateTrainSelectedArray.value], async ([newTabIndex, newTrainSelected]) => {
      if (newTrainSelected[newTabIndex]) {
        const train = newTrainSelected[newTabIndex]
        await getFaresByTrain(train.id, train.trainCode)
      }
    }, { immediate: true })

    const tabIndexRef = computed({
      get() { return store.state['app-train'].tabIndex },
      set(newValue) { store.commit('app-train/SET_TAB_INDEX', newValue) },
    })

    onBeforeMount(() => {
      if (!payloadSearch) {
        toastWarning({ title: 'train.search.result.pleaseSearchAgain' })
        router.push({ name: 'apps-trainSearch' })
      } else {
        searchTrain(payloadSearch)
      }
    })

    onUnmounted(() => {
      store.commit('app-train/RESET_STORE')

      if (store.hasModule('app-train')) {
        store.unregisterModule('app-train')
      }
    })

    function handleBackToSearch() {
      router.push({ name: 'apps-trainSearch' })
    }

    return {
      isBusy,
      loading,
      loadingGetFares,
      tabIndexRef,
      dataFare,
      trainName,
      stateTrainSelectedArray,
      getSearchPayloadArray,
      convertISODateTime,
      formatCurrency,
      payloadSearch,
      getResultTrainArray,

      isEmpty,
      handleBackToSearch,
    }
  },
}
</script>

<style lang="scss">

</style>
