var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-1"
  }, [_c('NavBarSearch', {
    attrs: {
      "payload": _vm.payloadSearch
    }
  }), _c('ResultHeaderTab'), _c('b-tabs', {
    attrs: {
      "nav-wrapper-class": "d-none"
    },
    model: {
      value: _vm.tabIndexRef,
      callback: function callback($$v) {
        _vm.tabIndexRef = $$v;
      },
      expression: "tabIndexRef"
    }
  }, _vm._l(_vm.getSearchPayloadArray, function (_, itineraryIndex) {
    return _c('b-tab', {
      key: itineraryIndex
    }, [_vm.loading ? [_c('div', {
      staticClass: "d-flex-center text-primary mt-2 py-2"
    }, [_c('b-spinner', {
      staticClass: "mr-50",
      attrs: {
        "variant": "primary"
      }
    }), _c('span', [_vm._v(_vm._s(_vm.$t('train.search.result.searching')))])], 1)] : _vm.isEmpty(_vm.getResultTrainArray[itineraryIndex]) && !_vm.loading ? [_c('b-alert', {
      staticClass: "my-2 my-lg-3",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('div', {
      staticClass: "text-center alert-heading font-medium-4"
    }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.noTrainFound')) + " ")])])] : !_vm.isEmpty(_vm.getResultTrainArray[itineraryIndex]) ? [_c('div', [_c('TrainFront', {
      attrs: {
        "itineraryIndex": itineraryIndex,
        "trains": _vm.getResultTrainArray[itineraryIndex]
      }
    })], 1), _c('div', [_c('div', {
      staticClass: "fw-700 font-medium-3 text-center mb-50 text-uppercase"
    }, [_vm._v(" BẢNG GIÁ VÉ - "), _vm.trainName ? _c('span', {
      staticClass: "text-danger"
    }, [_vm._v(" Tàu " + _vm._s(_vm.trainName))]) : _vm._e()]), _c('b-table', {
      staticClass: "mb-50 border rounded-8px",
      attrs: {
        "bordered": "",
        "responsive": "",
        "small": "",
        "hover": "",
        "items": _vm.dataFare,
        "busy": _vm.isBusy,
        "show-empty": !_vm.isBusy && !_vm.loadingGetFares && _vm.isEmpty(_vm.dataFare),
        "empty-text": _vm.$t('noMatchingResult'),
        "fields": ['STT', 'seatName', 'seatCode', 'ticketPrice'],
        "thead-class": "text-dark font-weight-bolder"
      },
      scopedSlots: _vm._u([_vm._l(['STT', 'seatName', 'seatCode', 'ticketPrice'], function (column) {
        return {
          key: "head(".concat(column, ")"),
          fn: function fn(data) {
            return [_c('span', {
              key: column.key
            }, [_vm._v(" " + _vm._s(_vm.$t("train.search.result.columns.".concat(data.column))) + " ")])];
          }
        };
      }), {
        key: "table-busy",
        fn: function fn() {
          return [_c('div', {
            staticClass: "text-center text-warning my-2"
          }, [_c('b-spinner', {
            staticClass: "align-middle"
          }), _c('strong', {
            staticClass: "ml-1"
          }, [_vm._v(_vm._s(_vm.$t('train.search.result.searching')))])], 1)];
        },
        proxy: true
      }, {
        key: "cell(STT)",
        fn: function fn(data) {
          return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
        }
      }, {
        key: "cell(seatName)",
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_vm._v(" " + _vm._s(item.seatTypeName) + " ")];
        }
      }, {
        key: "cell(seatCode)",
        fn: function fn(_ref2) {
          var item = _ref2.item;
          return [_c('span', {
            staticClass: "fw-700"
          }, [_vm._v(_vm._s(item.seatTypeCode))])];
        }
      }, {
        key: "cell(ticketPrice)",
        fn: function fn(_ref3) {
          var item = _ref3.item;
          return [item.totalPrice ? _c('span', {
            class: "fw-700 text-info\n                  ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-2', "\n                ")
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalPrice)) + " ")]) : _c('span', {
            staticClass: "fw-700 font-medium-1 text-danger"
          }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.notHavePrice')) + " ")])];
        }
      }], null, true)
    })], 1)] : _vm._e()], 2);
  }), 1), _c('div', {
    staticClass: "d-flex-center mt-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-secondary",
      "pill": ""
    },
    on: {
      "click": _vm.handleBackToSearch
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.goBack')) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }